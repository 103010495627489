<template>
  <v-card :height="210">
    <v-card-title class="mb-0 pb-1">
      Customer Information
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-container class="mt-0">
        <v-row>
          <v-col
            v-if="!user && !isLoading"
            md="12"
          >
            <h3 class="font-weight-light">
              No customer is assigned to this meter
            </h3>
          </v-col>
          <v-col
            sm="4"
            md="3"
            cols="12"
            class="mb-4"
          >
            <details-text-shimmer v-show="isLoading" />
            <div
              v-if="user"
              v-show="!isLoading"
            >
              <h4 class="font-weight-light">
                Name
              </h4>
              <h4
                class="font-weight-medium"
              >
                {{ user.name }}
              </h4>
            </div>
          </v-col>
          <v-col
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer v-show="isLoading" />
            <div
              v-if="user"
              v-show="!isLoading"
            >
              <h4 class="font-weight-light">
                Email
              </h4>
              <h4
                v-if="user.email !== null"
                class="font-weight-medium"
              >
                {{ user.email }}
              </h4>
              <h4
                v-else
                class="font-weight-medium"
              >
                Not provided
              </h4>
            </div>
          </v-col>
          <v-col
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer v-show="isLoading" />
            <div
              v-if="user"
              v-show="!isLoading"
            >
              <h4 class="font-weight-light">
                Phone Number
              </h4>
              <h4
                class="font-weight-medium"
              >
                {{ user.phone }}
              </h4>
            </div>
          </v-col>
          <v-col
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer v-show="isLoading" />
            <div
              v-if="user"
              v-show="!isLoading"
            >
              <h4 class="font-weight-light">
                Account Number
              </h4>
              <h4
                class="font-weight-medium"
              >
                {{ user.account_number }}
              </h4>
            </div>
          </v-col>
          <v-col
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer
              v-show="isLoading"
              class="mt-2 mb-3"
            />
            <div
              v-if="user"
              v-show="!isLoading"
            >
              <h4 class="font-weight-light">
                Registration date
              </h4>
              <h4 class="font-weight-medium">
                {{ user.created_at | formatDate }}
              </h4>
            </div>
          </v-col>
          <v-col
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer v-show="isLoading" />
            <div
              v-if="user"
              v-show="!isLoading"
            >
              <h4 class="font-weight-light">
                Account Balance
              </h4>
              <h4
                class="font-weight-medium"
              >
                Ksh {{ user.account_balance | formatAccountBalance }}
              </h4>
            </div>
          </v-col>
          <v-col
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer v-show="isLoading" />
            <div
              v-if="user"
              v-show="!isLoading"
            >
              <h4 class="font-weight-light">
                Total debt
              </h4>
              <h4
                class="font-weight-medium"
              >
                Ksh {{ user.account_balance | formatTotalUserDebt(user.unaccounted_debt) }}
              </h4>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import DetailsTextShimmer from '@/components/partials/shimmers/DetailsTextShimmer.vue'

export default {
  components: {
    'details-text-shimmer': DetailsTextShimmer,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style>

</style>
